@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap');
*{font-family: 'Montserrat', sans-serif;}

html { scroll-behavior: smooth; }
.animate-opacity{animation:opac 0.8s}@keyframes opac{from{opacity:0} to{opacity:1}}
.animate-bottom{position:relative;animation:animatebottom 0.8s}@keyframes animatebottom{from{bottom:-40px;opacity:0} to{bottom:0;opacity:1};}
.bg-blue{background-color: #5591b6}
.bg-sanjuan{background-color: #3e5360!important; color:#ffffff!important;}
.bg-panache{background-color: #F0F2EF!important;color: #3e5360!important;}
.nightrider{color: #353535!important;}
.navbar{background-color: #3e5360}
.navbar-nav .nav-link{ color: #f0f2ef; }.navbar-nav .nav-link:hover, .navbar-nav .nav-link.active, .navbar-nav .show>.nav-.link{ color: white!important; }
.navbar-toggler{border-color: white!important;}
.navbar-toggler:focus{box-shadow: 0 0 0 0 rgba(255, 255, 255, 1)!important;}
.navbar-toggler-icon{background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28255, 255, 255, 1%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e")!important}

main, a, a:hover{color: #353535;}
h1{font-size: 2.5rem;font-weight: light;}
h2{font-size: 1.5rem;font-weight: light;}
h3{font-size: 1.25rem;font-weight: light;}
.navbar-brand img{width:200px;height:40px}
.social{width: 48px;}
.form-label {margin-top: 1rem;}